<template>
  <div>
    <vue-headful :title="pageTitle" />
    <div class="has-text-centered has-background-primary" style="margin-bottom: 20px;">
      <h1
        class="is-size-6 has-text-white"
        style="padding: 5px 0px"
      >{{ pageheading.toLocaleUpperCase() }}</h1>
    </div>

    <div style="max-width: 100%; margin: auto; overflow: hidden;"  id="body-content-area">
      <div v-if="isLoading && reportTypeItems.length === 0">
        <Loading />
      </div>
      <div :class="{ 'hidden': !isLoading && reportTypeItems.length === 0 }" style="overflow: hidden;">
        <div id="pagelayout">
          <div>
            <div v-if="!isLoading" id="buttongroup" class="bottom-bar" style="display: flex; justify-content: flex-end; padding-bottom: 1rem;">
              <div class="level">
                <div class="level-left">
                  <div class="field is-grouped">
                    <div class="control">
                      <!-- :disabled="isDragEnabled" -->
                      <a :class="['button is-accent', !$hasPermissions(clientSession, ['REPORT_TYPES'], 2) ? 'disabled' : '']">
                        <span class="icon">
                          <i class="fal fa-plus-square"></i>
                        </span>
                        <span>
                          <router-link
                            :to="{ name: !$hasPermissions(clientSession, ['REPORT_TYPES'], 2) ? '' : 'NewReportType', params: {  }}"
                            class="has-text-white"
                          >Add Report Type</router-link>
                        </span>
                      </a>
                    </div>
                    <div class="control">
                      <a :class="['button is-dark',!$hasPermissions(clientSession, ['REPORT_TYPES'], 2) ? 'disabled' : '']" @click="!$hasPermissions(clientSession, ['REPORT_TYPES'], 2) ? null : confirmDelete()" :disabled="isBtnDisabled">
                        <span class="icon">
                          <i class="fal fa-trash-alt"></i>
                        </span>
                        <span>Delete Report Type{{ hasPluralSelection ? 's' : '' }}</span>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!--:selectable="multiple,row"-->
            <kendo-grid
            :style="{maxHeight: maxHeight + 'px', height: maxHeight + 'px'}"
              id="grid"
              ref="typeGrid"
              :persist-selection="true"
              :data-source="reportTypeItems"
              :selected-field="selectedField"
              :pageable-always-visible="false"
              :sortable="false"
              :pageable="false"
              :total="totalRecords"
              :change="onSelectionChange"
            >
              <kendo-grid-column :selectable="true" :field="'selected'" :width="28"></kendo-grid-column>
              <kendo-grid-column
                :field="'name'"
                :title="'Name'"
                :width="300"
                :template="linkTemplate"
              ></kendo-grid-column>
              <kendo-grid-column :field="'code'" :title="'Report Type'"></kendo-grid-column>
              <kendo-grid-column :field="'category'" :title="'Category'"></kendo-grid-column>
              <kendo-grid-column :template="checkboxTemplate" :field="'isPersistent'" :title="'Do Not Group By Year'"></kendo-grid-column>
              <kendo-grid-column :field="'order'" :hidden="true"></kendo-grid-column>
              <kendo-grid-column :field="'createdAt'" :hidden="true"></kendo-grid-column>
              <kendo-grid-column :field="'updatedAt'" :hidden="true"></kendo-grid-column>
            </kendo-grid>
          </div>

          <div id="delete-modal" class="modal is-active" v-if="showDeleteModal">
            <div class="modal-background" @click="showDeleteModal = false"></div>
            <div class="modal-card">
              <header class="modal-card-head has-bg-danger">
                <div class="modal-card-title has-bg-danger">Confirm Delete</div>
                <a class="delete" aria-label="close" @click="showDeleteModal = false"></a>
              </header>
              <section class="modal-card-body">
                <div class="content">
                  Type "
                  <b>delete</b>" to confirm that you want to delete one or more items.
                </div>
                <div class="field">
                  <div class="control">
                    <input
                      type="text"
                      class="input"
                      placeholder="type `delete` and then click confirm"
                      v-focus
                      v-model.trim="confirmationText"
                      @keydown.enter.prevent="deleteIfConfirmed"
                    />
                  </div>
                </div>
              </section>
              <footer class="modal-card-foot">
                <a
                  class="button is-danger"
                  @click="deleteIfConfirmed"
                  :disabled="confirmationText !== 'delete'"
                >
                  <span class="icon">
                    <i class="fal fa-trash-alt"></i>
                  </span>
                  <span>Confirm</span>
                </a>
                <a class="button" @click="showDeleteModal = false">Cancel</a>
              </footer>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from 'vue'
import $ from 'jquery'
import kendo from '@progress/kendo-ui'
import Loading from './Loading'
import { mapState } from 'vuex'
import { activeSite } from '../vuex-actions'
import {
  Grid,
  GridInstaller
} from '@progress/kendo-grid-vue-wrapper'
import Template from './templates/ReportTypeLinkTemplate.vue'
import CbTemplate from './templates/ReportTypeCheckboxTemplate.vue'

let pageName = 'Report Type Management'

Vue.use(GridInstaller)

export default {
  components: {
    Loading,
    'kendo-grid': Grid
  },
  data () {
    return {
      maxHeight: 0,
      minResults: 0,
      input: {
        code: '',
        name: '',
        description: ''
      },
      dataRows: [],
      confirmationText: '',
      isLoading: true,
      showDeleteModal: false,
      selectedRows: [],
      selectedRowValue: true,
      props: {
        grid: Grid,
        field: String,
        filterType: String,
        value: [String, Number, Boolean, Date],
        operator: String
      },
      totalRecords: 0,
      pageheading: pageName,
      pageable: false,
      selectedField: 'selected',
      sort: [{ field: 'order', dir: 'asc' }],
      dataSource: new kendo.data.DataSource({
        schema: {
          model: {
            fields: {
              selected: { editable: false },
              name: { editable: false },
              isPersistent: { editable: false },
              code: { editable: false },
              order: { editable: false },
              createAt: { editable: false },
              updateAt: { editable: false }
            },
            id: 'code'
          }
        }
      })
    }
  },
  computed: {
    ...mapState([activeSite, 'clientSession']),
    pageTitle () {
      return pageName + ' - ' + this.activeSite.displayName
    },
    isBtnDisabled () {
      return !this.hasSelection || this.isDragEnabled
    },
    hasSelection () {
      return this.selectedRows.length > 0
    },
    hasPluralSelection () {
      return this.selectedRows.length > 1
    },
    reportTypeItems: function () {
      this.dataSource.data(this.dataRows)
      return this.dataSource
    },
    pageSizes () {
      let sizes = [this.minResults]
      let max = this.totalDocuments
      let counter = this.minResults
      let min = this.minResults

      if (max !== 0 && min !== 0) {
        while (counter < max) {
          let size = counter + 10
          sizes.push(size > max ? max : size)
          counter += 10
        }
      }

      return sizes
    }
  },
  mounted: async function () {
    let that = this
    this.isLoading = true
    this.$nextTick(() => {
      let elements = [
        '.has-text-centered.has-background-primary',
        '.bottom-bar'
      ]

      let func = async (results) => {
        let [numberOfResults, maxHeight] = results
        this.take = numberOfResults + 2
        this.perPage = numberOfResults + 2
        this.minResults = numberOfResults + 2
        this.maxHeight = maxHeight - 50

        this.onMount()
      }

      that.getListHeights('body-content-area', 40, 30, elements, func.bind(this))

      window.addEventListener('resize', () => {
        that.getListHeights('body-content-area', 40, 30, elements, func.bind(that))
      })
    })
  },
  methods: {
    async onMount () {
      await this.getDataFromApi()
      let that = this
      let grid = this.$refs.typeGrid.kendoWidget()

      grid.table.kendoSortable({
        filter: '>tbody >tr',
        cursor: 'move',
        placeholder: function (element) {
          return $('<tr colspan="4" class="placeholder"></tr>')
        },
        change: function (e) {
          const route = '/report-types/sort-order'

          let newIndex = e.newIndex
          let data = grid.dataSource.data()
          let dataItem = grid.dataSource.getByUid(e.item.data('uid'))

          grid.dataSource.remove(dataItem)
          grid.dataSource.insert(newIndex, dataItem)

          let idx = 0
          let codes = []
          data.forEach(type => {
            type.order = idx
            codes.push(type.code)
            idx++
          })

          try {
            that.axios
              .put(route, codes)
              .then(res => {
                that.refreshData()
              })
              .catch(err => {
                this.handleApiErr(err)
              })
          } catch (error) {
            that.handleApiErr(error)
          } finally {
            that.isLoading = false
          }
        }
      })
    },
    async refreshData () {
      await this.onMount()
    },
    async getDataFromApi () {
      try {
        let route = `/report-types`
        this.isLoading = true
        let response = await this.axios.get(route)
        this.dataRows = response.data.records.sort((a, b) => a.order - b.order)
        this.dataRows = this.dataRows.map(row => {
          row.category = row.category === 'user' ? 'User Document' : row.category === 'vendor' ? 'Vendor Document' : row.category === 'carrier' ? 'Carrier Document' : row.category === 'client' ? 'Client Document' : 'N/A'
          return row
        })
      } catch (error) {
        this.handleApiErr(error)
      } finally {
        this.isLoading = false
      }
    },
    async updateOrder () {
      try {
        let route = `/report-types/sort-order`
        this.isLoading = true
        let response = await this.axios.put(
          route,
          this.dataRows.map(x => x.code)
        )
        this.dataRows = response.data
        this.successToast('Saved!')
      } catch (error) {
        this.handleApiErr(error)
      } finally {
        this.isLoading = false
      }
    },
    deleteIfConfirmed (event) {
      if (this.confirmationText === 'delete') {
        this.deleteSelected(
          '/report-types',
          'codes',
          'Unable to delete Report Type(s). Please contact system administrator.'
        )
      }
    },
    parameterMap: function (options, operation) {
      if (operation !== 'read' && options.models) {
        return { models: kendo.stringify(options.models) }
      }
    },
    sortChangeHandler: function (e) {
      this.sort = e.sort
    },
    onSelectionChange (event) {
      let selectedIds = []
      let selectedRows = $('tr.k-state-selected')

      for (let item of selectedRows) {
        let dataItem = event.sender.dataItem(item)
        let id = dataItem.id
        selectedIds.push(id)
      }

      this.selectedRows = selectedIds
      Vue.set(selectedIds, this.selectedRows)
    },
    isPersistent () {
      return false
    },
    linkTemplate: function (e) {
      return {
        router: this.$router,
        template: Vue.component(Template.name, Template),
        templateArgs: Object.assign({}, e, {
          parentComponent: this.$refs.typGrid
        })
      }
    },
    checkboxTemplate: function (e) {
      return {
        template: Vue.component(CbTemplate.name, CbTemplate),
        templateArgs: Object.assign({}, e, {
          parentComponent: this.$refs.typGrid,
          disabled: !this.$hasPermissions(this.clientSession, ['REPORT_TYPES'], 2)
        })
      }
    }
  }
}
</script>

<style>
/* Page Layout */
#body-content-area {
  position: absolute;
  top: 35px;
  bottom: 0;
  left: 0;
  width: 100%;
  padding: 20px;
  overflow-y: auto;
}
#pagelayout {
  right: 100%;
  bottom: 0;
}
.k-widget k-dateinput k-state-default k-label {
  font-weight: 600 !important;
  padding: 8px;
}
.k-checkbox:checked {
  background-color: #7fb942;
  color: white;
  border-color: #7fb942;
}
.k-pager-numbers .k-state-selected {
  background-color: #7fb942;
  color: white;
  border-color: #7fb942;
}
.k-grid th {
  font-weight: 600 !important;
}
.k-grid-header .k-header:first-child,
.k-grid th:first-child,
.k-grid td:first-child {
  width: 40px !important;
}
.k-grid-header .k-header:first-child,
.k-grid th:first-child,
.k-grid td:first-child {
  width: 40px !important;
}
.k-grid td.k-state-selected,
.k-grid tr.k-state-selected > td {
  background-color: #cbe3b3;
  opacity: 0.8;
  font-weight: 600;
}
.k-list .k-item.k-state-selected {
  background-color: #7fb942;
}
.k-list .k-item.k-state-selected:hover {
  background-color: #cbe3b3;
  color: #0d0c0c;
}
.k-date-picker {
  width: 90%;
  margin-right: 6px;
  border: pink;
}
a.download,
a.bcc-color {
  color: #7fb942 !important;
}
.page-subtitle {
  color: #7fb942;
  font-weight: 600;
  font-size: 1em;
  margin-bottom: 8px;
}
.k-invalid-msg {
  display: inline-block;
}
.invalid {
  color: red;
}
.valid {
  color: green;
}
.bcc-color {
  color: #7fb942;
}
.k-grid tbody tr {
  cursor: move;
}
.placeholder {
  outline-style: dashed;
  outline-width: 1px;
  outline-color: #7fb942;
}

.hidden {
  display: hidden!important;
}
.k-master-row > td:first-child {
  color: transparent!important;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 30px !important;
  margin: 0!important;
  padding: 0!important;
  height: 32px;
}
</style>
